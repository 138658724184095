.info-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 540px;
  text-align: center;
}

/* Collapse height of firebase ui loading spinner container */
.mdl-card .firebaseui-callback-indicator-container {
  height: 2px;
}
/* Collapse height of firebase ui loading spinner container */
.mdl-card.firebaseui-container {
  min-height: 2px;
  margin-bottom: 20px;
}
#firebaseui-auth-container ul.firebaseui-idp-list {
  padding-left: 0;
}
