.page-select-operation {
  .btn-container {
    display: flex;
    justify-content: center;
  }
  .btn {
    width: 270px;
    height: 40px;
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-wrap {
    margin-bottom: 4px;
  }
}
