@import 'app';
@import 'pages/select-operation.scss';
@import 'pages/apply.scss';

body {
  background-color: white !important;

  .page-header {
    text-align: center;
  }

  .sidebar-divider {
    margin: 0px 10px;
    background: #6e6e6e;
  }
}
