#root {
  .header {
    .navbar-logo-accrete {
      width: 80px;
      height: 32px;
      display: inline-block;
      background-image: url("/accrete_logo.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    
    .navbar-logo-label {
      margin-top: 14px;
      margin-left: 4px;
    }
    
    .navbar-user img {
      margin: 0;
    }
  }
}
