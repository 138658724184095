.page-apply {
  .buttons-container {
    display: flex;
    justify-content: flex-end;

    .btn {
      min-width: 70px;
    }
  }
}
